<template>
  <div class="listCategory">
    <div class="container-fluid container-edit pt-5 pb-4">
      <h1 class="text-center title-mobile pb-2">{{$t('listCategoryTitle')}}</h1>
    </div>
    <div class="container-fluid container-mobile-content d-flex justify-content-center pt-5 pr-3 pl-3">
      <div class="row pr-5 pl-5 d-flex justify-content-center">
        <div class="card ml-5 mr-5 mb-5" v-for="(category, id) in categoriesParents" :key="id" style="width: 20rem;">
          <a class="card-edit" :id="'c' + category.id" :href="'/category/edit/' + category.id">
            <i class="fas fa-pencil-alt"></i>
          </a>
          <b-tooltip :target="'c' + category.id" triggers="hover">
            <span>{{$t('edit')}}</span>
          </b-tooltip>
          <a :href="'/category/list/child/' + category.id">
            <img class="card-img-top category-img" :src="url + 'catalog/category/image/' + category.image" alt="Card image cap">
          </a>
          <div class="card-body">
            <div class="row card-category-content">
              <div class="col-9 align-items-center">
                <div>
                  <h5 class="card-title">{{category.name[$i18n.locale]}}</h5>
                </div>
                <div>
                  <h5 class="card-id">{{$t('categoryIDLabel')}} : {{category.id}} / {{$t('categorySortLabel')}} : {{category.sorting}}</h5>
                </div>
              </div>
              <div class="col-3 d-flex justify-content-center" v-on:click.prevent="editVisibility(category)" :id="'v' + category.id" :class="{ 'button-active':  category.visible, 'button-desactivate':  !category.visible }">
                <i v-if="category.visible" class="fas fa-eye" key="eye"></i>
                <i v-else class="fas fa-eye-slash" key="eyeslash"></i>
              </div>
              <b-tooltip :target="'v' + category.id" triggers="hover">
                <span v-if="category.visible">{{$t('visible')}}</span>
                <span v-else>{{$t('invisible')}}</span>
              </b-tooltip>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <Setting :location="'category/list'" /> -->
  </div>
</template>

<script>

var utils = require('@/assets/js/utils');
import Setting from '@/components/Settings.vue';

export default {
  name : 'ListCategory',
  components:{
    Setting,
  },
  data() {
    return {
      category: {
        name: {fr: '', en: ''},
        image: null,
        parentCategory: undefined,
        sorting: null,
        visible: true,
        imageBase64: null,
      },
      categories: [],
      categoriesParents: [],
      isEmpty: false,
      url: utils.getConfig().URL,
    }
  },
  methods: {
    getID(name){
      var cat = this.categories.find(c => c.name == name)
      if(cat != undefined){
        return cat.id;
      }
      else{ return null; }
		},
    editVisibility(category){
      if(category.visible){
        var visibility = false;
      }
      else{
        var visibility = true;
      }
      var dataToInsert = { 
        id : category.id,
        name:[
          {"lang": "fr", "value": category.name.fr},
          {"lang": "en", "value": category.name.en},
        ],
        is_visible : visibility,
        parent_category: category.parentCategory,
        sorting: category.sorting
      }

      const formData = new FormData();
      formData.append('params', JSON.stringify(dataToInsert));

      let loader = this.$loading.show({
        container: this.fullPage ? null : this.$refs.formContainer,
        canCancel: false,
        onCancel: this.onCancel,
        backgroundColor: '#000000',
        color: '#fff',
        opacity: 0.7,
        width: 100,
        height: 100,
      });

      this.$http.post(this.url + 'catalog/category/save', formData)
      .then(function(response){

        this.categoriesParents.filter((element)=>{
          if(element.id == category.id){
            element.visible = visibility;
          }
        })
        loader.hide();
      })
      .catch(function (e){
          console.log(e);
      })
    },
  },
  mounted() {
    this.$http.get(utils.getConfig().URL + 'catalog/category/get/all/formated')
    .then((response) => {
      this.categories = response.data;

      this.$http.get(utils.getConfig().URL + 'catalog/category/get/parent/all')
      .then((response) => {

        let loader = this.$loading.show({
          container: this.fullPage ? null : this.$refs.formContainer,
          canCancel: false,
          onCancel: this.onCancel,
          backgroundColor: '#000000',
          color: '#fff',
          opacity: 0.7,
          width: 100,
          height: 100,
        });

        this.categoriesParents = response.data;
        this.categoriesParents.sort((a, b) => a.sorting - b.sorting);
        loader.hide();
      });
    });
  }
}
</script>


